import React from "react";
import { Link } from "react-router-dom";

const VideoCard = ({ video, className }) => {
  return (
    <div
      className={`overflow-hidden rounded-xl shadow-[rgba(7,_65,_210,_0.1)_0px_9px_30px] ${className}`}
    >
      <Link to={`/videos/${video.slug}`}>
        <img
          src={video.thumbnail ? video.thumbnail : "default-thumbnail.png"} // Replace with your default thumbnail
          alt={video.title}
          className="h-auto w-full object-cover object-center md:h-52 lg:h-48 xl:h-60"
        />
      </Link>
      <div className="p-5">
        <Link to={`/videos/${video.slug}`}>
          <h2 className="font-roboto text-xl font-bold text-dark-soft md:text-2xl lg:text-[28px]">
            {video.title}
          </h2>
          <p className="mt-3 text-sm text-dark-light md:text-lg">
            {video.description}
          </p>
        </Link>
        <div className="mt-6 flex flex-nowrap items-center justify-between">
          <span className="text-sm font-bold italic text-dark-light md:text-base">
            {new Date(video.uploadedAt).toDateString()}
          </span>
        </div>
      </div>
    </div>
  );
};

export default VideoCard;
