// src/services/index/videos.js

import axios from "axios";

const BASE_URL = "https://your-api-endpoint.com/videos"; // Replace with your actual API URL

export const getAllVideos = async (
  searchKeyword = "",
  page = 1,
  pageSize = 12
) => {
  try {
    const response = await axios.get(`${BASE_URL}`, {
      params: {
        search: searchKeyword,
        page,
        pageSize,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error("Failed to fetch videos");
  }
};
