import React from "react";
import { useParams } from "react-router-dom";
import MainLayout from "./MainLayout";
//import MainLayout from "../../components/MainLayout";

const VideoDetailPage = () => {
  const { slug } = useParams();
  // Fetch video details using `slug`

  return (
    <MainLayout>
      <section className="container mx-auto px-5 py-10">
        <div className="flex flex-col items-center">
          {/* Replace with actual video URL and details */}
          <video controls className="mb-6 w-full max-w-3xl">
            <source src={`video-url/${slug}.mp4`} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          <h1 className="mb-4 text-3xl font-bold">Video Title</h1>
          <p className="text-lg">Video description goes here.</p>
        </div>
      </section>
    </MainLayout>
  );
};

export default VideoDetailPage;
