import React from "react";

const VideoCardSkeleton = ({ className }) => {
  return (
    <div className={`animate-pulse ${className}`}>
      <div className="h-48 rounded-md bg-gray-200 md:h-52 lg:h-60"></div>
      <div className="p-5">
        <div className="mb-3 h-6 w-3/4 rounded-md bg-gray-200"></div>
        <div className="h-4 w-1/2 rounded-md bg-gray-200"></div>
      </div>
    </div>
  );
};

export default VideoCardSkeleton;
