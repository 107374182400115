import React from "react";
import MainLayout from "../../components/MainLayout";

const AboutUs = () => {
  return (
    <MainLayout>
      <section className="container mx-auto flex flex-col px-5 py-10">
        <h1 className="text-dark mb-6 text-3xl font-bold md:text-4xl">
          About Us
        </h1>
        <p className="mb-6 text-lg text-gray-700 md:text-xl">
          Welcome to [Your Company Name]! We are committed to providing
          excellent service and delivering high-quality products to our
          customers.
        </p>
        <p className="mb-6 text-lg text-gray-700 md:text-xl">
          Our mission is to [insert mission statement or company values]. Our
          team is dedicated to ensuring customer satisfaction and innovation in
          [industry/field].
        </p>
        <p className="mb-6 text-lg text-gray-700 md:text-xl">
          With years of experience and a passion for what we do, we strive to
          make a positive impact in the lives of our customers and community.
        </p>
        <p className="mb-6 text-lg text-gray-700 md:text-xl">
          If you have any questions or would like to learn more about us, please{" "}
          <a
            href="mailto:info@example.com"
            className="text-blue-500 hover:underline"
          >
            contact us
          </a>
          .
        </p>
        <div className="mt-6">
          <a href="/" className="text-blue-500 hover:underline">
            Back to Home
          </a>
        </div>
      </section>
    </MainLayout>
  );
};

export default AboutUs;
